import Lines from "../models/lines";
const linesData = [
    //Tramo 5
    // new Lines(
    //     "Tramo 5 (Playa del Carmen)", //label
    //     "christianjltz.awyf6r23", //id 
    //     20.63714, //lat
    //     -87.08035,//long
    //     12.43,//zoom
    //     "Tramo_5_-_Playa_del_Carmen" //source
    // ),
    // new Lines(
    //     "Tramo 5 (Norte)", //label
    //     "christianjltz.04mci9zl", //id 
    //     20.8389, //lat
    //     -86.955,//long
    //     10.43,//zoom
    //     "T5_nte" //source
    // ),
    // new Lines(
    //     "Tramo 5 (Sur)", //label
    //     "christianjltz.9fspm1g5", //id 
    //     20.4167, //lat
    //     -87.2811,//long
    //     10.28,//zoom
    //     "T5_sur" //source
    // ),
    // new Lines(
    //     "Tramo 6 (A)", //label
    //     "christianjltz.0d1blnf3", //id 
    //     19.8266, //lat
    //     -87.7667,//long
    //     9.16,//zoom
    //     "Tramo_6A" //source
    // ),
    // new Lines(
    //     "Tramo 6 (B)", //label
    //     "christianjltz.bbr1lhj9", //id 
    //     18.9625, //lat
    //     -88.2678,//long
    //     9.08,//zoom
    //     "Tramo_6B" //source
    // ),
    // new Lines(
    //     "Tramo 7 (Ramal)", //label
    //     "christianjltz.8i5hx34b", //id 
    //     18.3268, //lat
    //     -89.8246,//long
    //     10.15,//zoom
    //     "ramal_Tramo_7" //source
    // ),
    // new Lines(
    //     "Tramo 7", //label
    //     "christianjltz.21kh795f", //id 
    //     18.5514, //lat
    //     -89.6107,//long
    //     8.49,//zoom
    //     "Tramo_7" //source
    // ),    
    // new Lines(
    //     "", //label
    //     "christianjltz.c5yokq88", //id 
    //     19.566, //lat
    //     -88.798 ,//long
    //     6.8,//zoom
    //     "TRAMO_COMPLETO1KM" //source
    // ),    
    // new Lines(
    //     "", //label
    //     "christianjltz.28vtkup6", //id 
    //     18.294875, //lat
    //     -96.222102,//long
    //     15,//zoom
    //     "Terreno-1" //source
    // ),
    new Lines(
        "", //label
        "christianjltz.0x8ediya", //id 
        18.294875, //lat
        -96.222102,//long
        18,//zoom
        "Camino-a-cava" //source
    ),
    // new Lines(
    //     "", //label
    //     "christianjltz.b19jhi3i", //id 
    //     18.294875, //lat
    //     -96.222102,//long
    //     18,//zoom
    //     "Area-cava" //source
    // ),


];

export default linesData;