import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import LinesGroup from './../../../models/linesGroup';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const addSource = (mapRef, tileSet) => {
    mapRef.current.addSource(tileSet.id, {
        type: 'raster',
        url: `mapbox://${tileSet.id}`
    });
}
const addLayer = (mapRef, tileSet) => {
    mapRef.current.addLayer({
        'id': tileSet.id,
        'source': tileSet.id,
        type: 'raster'
    });
}

// const addSource = (mapRef, tileSet) => {
//     mapRef.current.addSource(tileSet.id, {
//         type: 'raster',
//         url: `mapbox://${tileSet.id}`
//     });
// }
// const addLayer = (mapRef, tileSet) => {
//     mapRef.current.addLayer({
//         'id': tileSet.id,
//         'source': tileSet.id,
//         type: 'raster'
//     });
// }
const places = {
    'type': 'FeatureCollection',
    'features': [
        {
            'type': 'Feature',
            'properties': {
                'description': "Ford's Theater",
                'icon': 'theatre-15'
            },
            'geometry': {
                'type': 'Point',
                'coordinates': [18.294875, -96.222102]
                // 'coordinates': [-77.038659, 38.931567]
            }
        },
    ]
};



const removeSource = (map, tileSet) => {
    map.current.removeSource(tileSet.id);
}

const removeLayer = (map, tileSet) => {
    map.current.removeLayer(tileSet.id);
}

const removeLayerAndSource = (map, tile) => {
    map.current.removeLayer(tile.id);
    map.current.removeSource(tile.id);
}

const addAllVectors = (map) => {
    const linesGroup = new LinesGroup();
    linesGroup.lines.map(line => addVectorToMap(map, line));
}
const removeAllVectors = (map) => {
    const linesGroup = new LinesGroup();
    linesGroup.lines.map(line => removeLayerAndSource(map, line));
}

const flyTo = (mapsRef, lat, long, zoom) => {
    const flyToLocation = (map) => {
        map.current.flyTo({
            center: [
                long,
                lat,
            ],
            zoom: zoom,
            essential: false
        });
    }
    const [mainMap, beforeCompareMap, afterCompareMap] = mapsRef;
    if (mainMap) flyToLocation(mainMap);
    if (beforeCompareMap) flyToLocation(beforeCompareMap);
    if (afterCompareMap) flyToLocation(afterCompareMap);
}



// const addLabelToMap = (map) => {
//     map.addSource('places', {
//         'type': 'geojson',
//         'data': places
//     });

//     map.addLayer({
//         'id': 'poi-labels',
//         'type': 'symbol',
//         'source': 'places',
//         'layout': {
//             'text-field': ['get', 'description'],
//             'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
//             'text-radial-offset': 0.5,
//             'text-justify': 'auto',
//             'icon-image': ['get', 'icon']
//         }
//     });

// }


const addVectorToMap = (map, vector) => {
    map.current.addSource(vector.id, {
        type: 'vector',
        url: `mapbox://${vector.id}`
    });
    map.current.addLayer({
        'id': vector.id,
        'type': 'line',
        'source': vector.id,
        'source-layer': vector.source,
        'layout': {
            'line-join': 'round',
            'line-cap': 'round'
        },
        'paint': {
            'line-color': '#627BC1',
            'line-width': 4,
        }
    });

    // const popup = new mapboxgl.Popup({
    //     closeButton: false,
    //     closeOnClick: false
    // });


    map.current.on('mousemove', vector.id, (e) => {
        map.current.setPaintProperty(vector.id, 'line-color', '#627BC1');
        map.current.getCanvas().style.cursor = 'pointer';
        const coordinates = [vector.lng, vector.lat]
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
        // popup.setLngLat(coordinates).setHTML(vector.legend).addTo(map.current);

    });
    map.current.on('mouseleave', vector.id, () => {
        map.current.setPaintProperty(vector.id, 'line-color', '#627BC1');
        // popup.remove();
        map.current.getCanvas().style.cursor = '';
    });
    map.current.on('click', vector.id, (e) => {
        map.current.flyTo({
            center: [
                vector.lng,
                vector.lat,
            ],
            zoom: vector.zoom,
            essential: false
        });
    })
}

//remove layers, restyle and readd layers
const restartWithlayers = (mapRef, newMapStyle, tileset) => {
    removeLayerAndSource(mapRef, tileset);
    removeAllVectors(mapRef)
    mapRef.current.setStyle(newMapStyle);
    setTimeout(() => {
        addSource(mapRef, tileset);
        addLayer(mapRef, tileset);
        addAllVectors(mapRef);
    }, 500);
}

const changeTileset = (mapRef, oldTile, newTile) => {
    removeLayerAndSource(mapRef, oldTile,);
    removeChildrenIfExist(mapRef, oldTile);
    setTimeout(() => {
        addSource(mapRef, newTile);
        addLayer(mapRef, newTile);
        loadChildrenIfExist(mapRef, newTile);
        takeVectorsToTheFront(mapRef)
    }, 500);
}

const takeVectorsToTheFront = (mapRef) => {
    const linesGroup = new LinesGroup();
    linesGroup.lines.map(line => mapRef.current.moveLayer(line.id));
}

const loadChildrenIfExist = (mapRef, tileset) => {
    if (tileset.children !== null) {
        const childrenLayer = tileset.children[0]
        addSource(mapRef, childrenLayer);
        addLayer(mapRef, childrenLayer);
    }
}

const removeChildrenIfExist = (mapRef, tileset) => {
    if (tileset.children !== null) {
        tileset.children.forEach(childrenLayer => {
            removeLayerAndSource(mapRef, childrenLayer);
        })
    }
}

const geocoder = new MapboxGeocoder({
    accessToken: mapboxgl.accessToken,
    mapboxgl: mapboxgl,
    language: 'ES'
});

const addSearchBox = (map) => {
    var element = document.getElementById("geocoder");
    element.parentNode.removeChild(element);
    var parent = document.getElementById("search-bar-parent");
    const searchContainer = document.createElement('div');
    searchContainer.setAttribute("id", "geocoder");
    parent.appendChild(searchContainer);
    document.getElementById('geocoder').appendChild(geocoder.onAdd(map.current));
};


export { addSource, addLayer, removeSource, removeLayer, restartWithlayers, changeTileset, addAllVectors, flyTo, loadChildrenIfExist }
// export { addSource, addLayer, removeSource, removeLayer, restartWithlayers, changeTileset, addAllVectors, flyTo, addSearchBox, loadChildrenIfExist }