import React, { useRef, useEffect, useContext } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import MapContext from '../MapsContext';
import { addLayer, addSource, addAllVectors, loadChildrenIfExist } from '../helpers';
// import { addLayer, addSource, addAllVectors, loadChildrenIfExist, addSearchBox } from '../helpers';
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const MainMap = ({ setMainMap, mainTileSet }) => {
    const mapContext = useContext(MapContext);
    const mapContainer = useRef(null);
    const map = useRef(null);


    const places = {
        'type': 'FeatureCollection',
        'features': [
            {
                'type': 'Feature',
                'properties': {
                    'description': "Cava \n  (466m2)",
                    // 'icon': 'theatre-15'
                },
                'geometry': {
                    'type': 'Point',
                    'coordinates': [-96.221259, 18.294722]
                    // 'coordinates': [-77.038659, 38.931567]
                }
            },
            {
                'type': 'Feature',
                'properties': {
                    'description': "Terreno-1  \n (10, 541m2)",
                    // 'icon': 'theatre-15'
                },
                'geometry': {
                    'type': 'Point',
                    'coordinates': [-96.222302, 18.294558]
                    // 'coordinates': [-77.038659, 38.931567]
                }
            },
        ]
    };

    const addLabelToMap = (map) => {
        map.current.addSource('places', {
            'type': 'geojson',
            'data': places
        });

        map.current.addLayer({
            'id': 'poi-labels',
            'type': 'symbol',
            'source': 'places',
            'layout': {
                'text-field': ['get', 'description'],
                'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
                'text-radial-offset': 0.5,
                'text-justify': 'auto',
                'icon-image': ['get', 'icon']
            }
        });

    }

    useEffect(() => {
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: mapContext.mainBackgroundStyle,
            center: [mapContext.lng, mapContext.lat],
            zoom: mapContext.zoom,
            attributionControl: false,
            logoPosition: 'bottom-right'
        });

        map.current.on('load', () => {
            map.current.resize();
            addSource(map, mainTileSet);
            addLayer(map, mainTileSet);
            loadChildrenIfExist(map, mainTileSet);
            addAllVectors(map);
            // addLabelToMap(map)

            // addSearchBox(map);
        });

        setMainMap(map);

        return (() => {
            setMainMap(null);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);// no remover [], 

    useEffect(() => {
        if (!map.current) return; // wait for map to initialize
        map.current.on('move', () => {
            mapContext.setLng(map.current.getCenter().lng.toFixed(4));
            mapContext.setLat(map.current.getCenter().lat.toFixed(4));
            mapContext.setZoom(map.current.getZoom().toFixed(2));
        });
    });

    return (
        <>
            <div ref={mapContainer} className="map-container" style={{ width: '100%' }} />
        </>
    );
}

export default MainMap;