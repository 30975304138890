import React, { useState } from "react";

//components
import BaseMapModal from "./BaseMapModal";
import { MainTileSetList } from "./MainTileSetList";
import { SecondaryTilesetList } from "./SecondaryTilesetList";
// import { SearchBar } from "./SearchBar";
import regions from "../../../../data/regionsData";

//icons
import menu1 from "./vectors/menu1.png";
import menu2 from "./vectors/menu2.png";
import chincheta2_1 from "./vectors/chincheta2_1.png";
import chincheta2_2 from "./vectors/chincheta2_2.png";
import observacion1 from "./vectors/observacion1.png";
import observacion2 from "./vectors/observacion2.png";
import busqueda1 from "./vectors/busqueda1.png";
import busqueda2 from "./vectors/busqueda2.png";
import regla1 from "./vectors/regla1.png";
import regla2 from "./vectors/regla2.png";
import comparativo1 from "./vectors/comparativo1.png";
import comparativo2 from "./vectors/comparativo2.png";
import MeasureDistance from "./vectors/MeasureDistances";

export const SidebarLeft = ({
  mainBackgroundStyle,
  changeBaseMap,
  changeMainTileset,
  mainTileSet,
  setMainTileset,
  setTilesetComparation,
  setIsComparingActive,
  isComparingActive,
  tilesetForComparation,
}) => {
  const [tilesetList, setTileSetList] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(regions[0]);
  return (
    <div className="fixed flex flex-col z-30 h-full">
      <div className="h-16"></div> {/*Div que empata la altura del navbar*/}
      <div
        className={`flex-col bg-color6 text-white justify-between align-middle flex-grow mt-2`}
      >
        {/* <MenuOption icon1={menu1} icon2={menu2} component={EmtyContainer} />
        <hr /> */}
        <MenuOption
          icon1={observacion1}
          icon2={observacion2}
          component={MainTileSetList}
          // text="Puntos de observación"
          show={false}
          changeMainTileset={changeMainTileset}
          mainTileSet={mainTileSet}
          setMainTileset={setMainTileset}
          tilesetList={tilesetList}
          setTileSetList={setTileSetList}
          selectedRegion={selectedRegion}
          setSelectedRegion={setSelectedRegion}
        />
        {/* <MenuOption
          icon1={comparativo1}
          icon2={comparativo2}
          component={SecondaryTilesetList}
          text="Comparativo"
          show={false}
          setTilesetComparation={setTilesetComparation}
          setIsComparingActive={setIsComparingActive}
          isComparingActive={isComparingActive}
          tilesetForComparation={tilesetForComparation}
          mainTileSet={mainTileSet}
          tilesetList={tilesetList}
          selectedRegion={selectedRegion}
        /> */}
        {/* <MenuOption
          icon1={chincheta2_1}
          icon2={chincheta2_2}
          component={BaseMapModal}
          text="Tipo de mapa base"
          show={false}
          mainBackgroundStyle={mainBackgroundStyle}
          changeBaseMap={changeBaseMap}
        /> */}
        {/* <MenuOption
          icon1={busqueda1}
          icon2={busqueda2}
          component={SearchBar}
          text="Buscar ubicación"
          show={false}
        /> */}
        {!isComparingActive ? <MeasureDistance icon1={regla1} icon2={regla2} text="" /> : ''}
        {/* {!isComparingActive ? <MeasureDistance icon1={regla1} icon2={regla2} text="Distancia entre puntos" /> : ''} */}


        <button
          className={`text-white bg-gray-900 shadow-2xl px-3 h-10 w-full mt-3  ${isComparingActive ? "" : "hidden"
            }`}
          onClick={() => {
            setIsComparingActive(false);
          }}
        >
          Cancelar comparación
        </button>
      </div>
    </div>
  );
};



const MenuOption = ({ icon1, icon2, component, text, show, ...rest }) => {
  const [isOptionHovered, setIsOptionHovered] = useState(false);
  const Component = component;
  return (
    <div
      className="flex overflow-hidden group w-100 bg-color6"
      onMouseOver={() => setIsOptionHovered(true)}
      onMouseLeave={() => setIsOptionHovered(false)}
    >
      <div className="z-30 p-3">
        <img
          src={!isOptionHovered ? icon1 : icon2}
          className=" w-10 h-10 group-hover:animate-zoom cursor-pointer"
          alt=""
        />
      </div>
      <div
        className={`flex flex-col justify-center p-2 pr-4 group  whitespace-nowrap z-20 text-white`}
      >
        <p
          className={`group-hover:animate-zoom2 ${!isOptionHovered ? "text-color1" : "text-color1"
            }`}
        >
          {text}
        </p>
      </div>

      <div
        className={`transition ease absolute left-full ${(isOptionHovered || show) ? "block" : "hidden"
          }`}
      >
        <Component {...rest} />
      </div>
    </div>
  );
};

const EmtyContainer = () => {
  return <p></p>;
};
