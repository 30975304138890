import React, { useState } from "react";

//data
import TilesetsGroup from "../../../models/tilesetsgroup";
import mapStyles from "../../../data/mapStyles";

//storage
import MapContext from "./MapsContext";

//components
import CompareMap from "./components/CompareMap";
import MainMap from "./components/MainMap";
import { Navbar } from '../../../components/Navbar';
import { Footer } from '../../../components/Footer';
import { SidebarLeft } from './components/SidebarLeft';
import { restartWithlayers, changeTileset } from './helpers';

//chat
import { Userlike } from 'react-live-chat-loader'
import { OpacityChanger } from "./components/OpacityChanger";
import { SidebarRight } from "./components/SidebarRight";
import { useEffect } from "react";

export default function MapContainer() {
  const tilesets = new TilesetsGroup();
  let latestTileset = tilesets.returnLatestTileset();

  const [mainMap, setMainMap] = useState("asdf");
  const [compareBeforeMap, setCompareBeforeMap] = useState(null);
  const [compareAfterMap, setCompareAfterMap] = useState(null);
  const allMapsRefs = [mainMap, compareBeforeMap, compareAfterMap];

  const [mainBackgroundStyle, setMainBackgroundStyle] = useState(
    mapStyles[0].id
  );
  const [lng, setLng] = useState(-96.220281);
  const [lat, setLat] = useState(18.296403);
  const [zoom, setZoom] = useState(15);

  const [mainTileSet, setMainTileset] = useState(latestTileset);
  const [tilesetForComparation, setTilesetForComparation] = useState(false);

  const [isComparingActive, setIsComparingActive] = useState(false);


  const mapData = {
    lat: lat,
    setLng: setLng,
    lng: lng,
    setLat: setLat,
    zoom: zoom,
    setZoom: setZoom,
    mainBackgroundStyle: mainBackgroundStyle,
    allMapsRefs: allMapsRefs,
  };

  const changeBaseMap = (newMapStyle) => {
    setMainBackgroundStyle(newMapStyle);
    if (mainMap) restartWithlayers(mainMap, newMapStyle, mainTileSet, false);
    if (compareBeforeMap) restartWithlayers(compareBeforeMap, newMapStyle, mainTileSet);
    if (compareAfterMap) restartWithlayers(compareAfterMap, newMapStyle, tilesetForComparation);
  };

  const changeMainTileset = (newTileset) => {
    if (mainMap) changeTileset(mainMap, mainTileSet, newTileset);
    if (compareBeforeMap) changeTileset(compareBeforeMap, mainTileSet, newTileset);
    setMainTileset(newTileset);
  };

  const setTilesetComparation = (secondaryTilset) => {
    if (isComparingActive) {
      changeTileset(compareAfterMap, tilesetForComparation, secondaryTilset);
    } else {
      setIsComparingActive(true); // el mapa iniciara con el tileset
    }
    setTilesetForComparation(secondaryTilset);
  };

  // useEffect(() => {
  //   const clima = async () => {
  //     // GET request using fetch with async/await
  //     const response = await fetch('http://dataservice.accuweather.com/forecasts/v1/daily/5day/locationKey=241964_PC?apikey=zen0PO5Mlw9P53QknBP2iQUHBHO7LvZq');
  //     const data = await response.json();
  //     console.log(data)
  //   }
  //   clima()
  // }, [])


  return (
    <>
      <MapContext.Provider value={mapData}>
        <Navbar />
        <SidebarLeft
          mainBackgroundStyle={mainBackgroundStyle}
          changeBaseMap={changeBaseMap}
          changeMainTileset={changeMainTileset}
          mainTileSet={mainTileSet}
          setMainTileset={setMainTileset}
          //SecondaryTilesetList
          setTilesetComparation={setTilesetComparation}
          setIsComparingActive={setIsComparingActive}
          isComparingActive={isComparingActive}
          tilesetForComparation={tilesetForComparation}
        />
        <SidebarRight lat={lat} lng={lng} right="0" />
        {isComparingActive ? <SidebarRight lat={lat} lng={lng} right="half" /> : ''}

        <div className="flex h-screen">
          {/* <Userlike /> */}
          {!isComparingActive ?
            <MainMap setMainMap={setMainMap} mainTileSet={mainTileSet} />
            :
            <CompareMap setCompareBeforeMap={setCompareBeforeMap} setCompareAfterMap={setCompareAfterMap} isComparingActive={isComparingActive} mainTileSet={mainTileSet} tilesetForComparation={tilesetForComparation} />
          }
        </div>
        {/* <OpacityChanger allMapsRefs={allMapsRefs} mainTileSet={mainTileSet} tilesetForComparation={tilesetForComparation} /> */}
        {/* <Footer /> */}
      </MapContext.Provider>
    </>
  )
}