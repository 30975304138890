import React, { useContext } from "react";
import { FaUserAlt } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import UserContext from "../context/UserContext";
import MeasureDistance from "../screens/maps/timeline/components/vectors/MeasureDistances";

import regla1 from "../screens/maps/timeline/components/vectors/regla1.png";
import regla2 from "../screens/maps/timeline/components/vectors/regla2.png";
import { useState } from "react";
import { WheatherDay } from "./WheatherDay";

export const Navbar = () => {
  const userContext = useContext(UserContext);
  const history = useHistory();
  const logout = () => {
    localStorage.removeItem("aposdijfmasoidjhfaowerfio");
    userContext.setIsLogged(false);
    history.push("/");
  };
  const [climaInfo, setClimaInfo] = useState()

  const padNum = (num) => {
    const stringNum = num + ''
    const stringLen = stringNum.length
    if (stringLen === 1) {
      return '0' + stringNum
    }
    else {
      return stringNum
    }

  }
  const fToC = (num) => {
    const stringNum = Math.round((num - 32) * 5 / 9 * 10) / 10
    return stringNum

  }


  useEffect(() => {
    const daysOfWeek = [
      'Domingo',
      'Lunes',
      'Martes',
      'Miercoles',
      'Jueves',
      'Viernes',
      'Sábado'
    ]
    const clima = async () => {
      const response = await fetch('https://dataservice.accuweather.com/forecasts/v1/daily/5day/locationKey=241964_PC?apikey=SVN5C9m4DnzU1eNJ4pkT4JP8pY3TAThq');
      const data = await response.json();
      console.log(data);

      setClimaInfo(
        data.DailyForecasts.map(df => {
          return {
            min: fToC(df.Temperature.Minimum.Value),
            max: fToC(df.Temperature.Maximum.Value),
            wT: df.Day.IconPhrase,
            wK: padNum(df.Day.Icon),
            dia: daysOfWeek[new Date(df.Date).getDay()]
            // date: df.EpochDate
            // date: month(df.EpochDate)
            // date: month(df.Date)
          }

        })
      )
    }
    clima()
  }, [])

  return (
    <div className="h-20 flex bg-white text-black justify-around  mx-20 px-20">
      {/* <div className="h-20 flex bg-white text-black px-4 justify-between "> */}
      {/* <img
          src="./static/img/logos/app-space2.png"
          className="h-14 transform duration-500 hover:scale-110 object-cover my-auto"
          alt=""
        /> */}
      {/* <h1 className="m-auto">La Esperanza</h1> */}

      {!!climaInfo && climaInfo.map((i, index) => (
        <div key={index}>
          <WheatherDay min={i.min} max={i.max} wT={i.wT} wK={i.wK} dia={i.dia} />
        </div>
      ))}



      {/* <button
        className=""
        onClick={() => {
          logout();
        }}
      >
        <FaUserAlt className="mx-auto h-6"></FaUserAlt>
        <p className="">Cerrar Sesión</p>
      </button> */}
    </div>
  );
};
