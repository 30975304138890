import React, { useState, useContext, useEffect } from 'react'
import * as turf from '@turf/turf';
import MapContext from '../../MapsContext';

let geojson = {
    'type': 'FeatureCollection',
    'features': []
};

// Used to draw a line between points
const linestring = {
    'type': 'Feature',
    'geometry': {
        'type': 'LineString',
        'coordinates': []
    }
};

const MeasureDistance = ({ icon1, icon2, text }) => {

    const [isOptionSelected, setIsOptionSelected] = useState(false);
    const mapContext = useContext(MapContext);
    const [mainMapRef, ,] = mapContext.allMapsRefs;



    return (
        <>
            <div className="flex overflow-hidden group w-100 bg-color6 "  >
                <div className="z-30 p-3 " onClick={() => setIsOptionSelected((e) => (!e))}>
                    <img src={!isOptionSelected ? icon1 : icon2} className=" w-10 h-10 group-hover:animate-zoom cursor-pointer" alt="" />
                </div>
                <div className={`flex flex-col justify-center p-2 pr-4 group  whitespace-nowrap z-20 text-white`} onClick={() => setIsOptionSelected((e) => (!e))} >
                    <p className={`group-hover:animate-zoom2 ${!isOptionSelected ? "text-color3" : "text-color1"}`} >
                        {text}
                    </p>
                </div>
                <div
                    className={`transition  ease absolute left-full ${isOptionSelected ? "block" : "hidden"
                        }`}
                >
                    <div id="search-bar-parent" className="bg-white py-4 px-2 rounded-r-xl text-black w-full">
                        {isOptionSelected ? <DistanceContainer mainMapRef={mainMapRef} /> : ' '}
                    </div>
                </div>
            </div>

        </>
    );
}

const DistanceContainer = (mainMapRef) => {
    const map = mainMapRef.mainMapRef;

    useEffect(() => {

        //Inicia codigo para medir distancias
        map.current.addSource('geojson', {
            'type': 'geojson',
            'data': geojson
        });

        // Add styles to the map
        map.current.addLayer({
            id: 'measure-points',
            type: 'circle',
            source: 'geojson',
            paint: {
                'circle-radius': 5,
                'circle-color': '#000'
            },
            filter: ['in', '$type', 'Point']
        });
        map.current.addLayer({
            id: 'measure-lines',
            type: 'line',
            source: 'geojson',
            layout: {
                'line-cap': 'round',
                'line-join': 'round'
            },
            paint: {
                'line-color': '#000',
                'line-width': 2.5
            },
            filter: ['in', '$type', 'LineString']
        });

        map.current.on('click', clickEvent);
        map.current.on('mousemove', startCrossHairListener);

    }, [])

    useEffect(() => {
        return () => {
            const map = mainMapRef;
            map.mainMapRef.current.removeLayer('measure-lines');
            map.mainMapRef.current.removeLayer('measure-points');
            map.mainMapRef.current.removeSource('geojson')
            map.mainMapRef.current.off('mousemove', startCrossHairListener)
            map.mainMapRef.current.off('click', clickEvent);
            geojson = {
                'type': 'FeatureCollection',
                'features': []
            };
            map.mainMapRef.current.getCanvas().style.cursor = '';
        }
    }, [])

    const clickEvent = (e) => {
        const features = map.current.queryRenderedFeatures(e.point, {
            layers: ['measure-points']
        });

        const distanceContainer = document.getElementById('distance');

        // Remove the linestring from the group
        // so we can redraw it based on the points collection.
        if (geojson.features.length > 1) geojson.features.pop();

        // Clear the distance container to populate it with a new value.
        distanceContainer.innerHTML = '';

        // If a feature was clicked, remove it from the map.

        if (features.length) {
            const id = features[0].properties.id;
            geojson.features = geojson.features.filter(
                (point) => point.properties.id !== id
            );
        } else {
            const point = {
                'type': 'Feature',
                'geometry': {
                    'type': 'Point',
                    'coordinates': [e.lngLat.lng, e.lngLat.lat]
                },
                'properties': {
                    'id': String(new Date().getTime())
                }
            };

            geojson.features.push(point);
        }

        if (geojson.features.length > 1) {
            linestring.geometry.coordinates = geojson.features.map(
                (point) => point.geometry.coordinates
            );

            geojson.features.push(linestring);
            // Populate the distanceContainer with total distance
            const value = document.createElement('pre');
            const distance = turf.length(linestring);
            value.textContent = `Distancia Total: ${distance.toLocaleString('en-US') * 1000}m`;
            distanceContainer.appendChild(value);
        }

        map.current.getSource('geojson').setData(geojson);

    }

    const startCrossHairListener = (e) => {
        const features = map.current.queryRenderedFeatures(e.point, {
            layers: ['measure-points']
        });
        // Change the cursor to a pointer when hovering over a point on the mainMapRef.
        // Otherwise cursor is a crosshair.
        map.current.getCanvas().style.cursor = features.length
            ? 'pointer'
            : 'crosshair';
    }


    return (
        <div id="distance" className=" z-50 w-full">Haga click en el mapa para medir una distancia</div>
    )
};


export default MeasureDistance;