import image from './../screens/maps/timeline/components/vectors/image.png';

const regions = [
    {
        name: "Inicio",
        id: "Punto 1", // empata al tileset
        img: image,
        lng: 18.297916,
        lat: -96.215988,
        zoom: 15,
    },
    {
        name: "Cava",
        id: "Punto 1", // empata al tileset
        img: image,
        lng: 18.294722,
        lat: -96.221259,
        zoom: 18,
    },
    {
        name: "Terreno-1",
        id: "Punto 1", // empata al tileset
        img: image,
        lng: 18.294558,
        lat: -96.222302,
        zoom: 18,
    },
    // {
    // name: "Escárcega",
    // id: "Punto 1", // empata al tileset
    // img: image,
    // lng: 18.586,
    // lat: -90.73151,
    // zoom: 12,
    // },
    // {
    //     name: "Balamkú",
    //     id: "Punto 2", // empata al tileset
    //     img: image,
    //     lng: 18.52496,
    //     lat: -89.89953,
    //     zoom: 12,
    // },
    // {
    //     name: "Calakmul",
    //     id: "Punto 3", // empata al tileset
    //     img: image,
    //     lng: 18.13969,
    //     lat: -89.78785,
    //     zoom: 12,
    // },
    // {
    //     name: "Ucum",
    //     id: "Punto 4", // empata al tileset
    //     img: image,
    //     lng: 18.50369,
    //     lat: -88.54685,
    //     zoom: 12,
    // },
    // {
    //     name: "Chetumal",
    //     id: "Chetumal", // empata al tileset
    //     img: image,
    //     lng: 18.51508,
    //     lat: -88.34495,
    //     zoom: 14,
    // },
    // {
    //     name: "Palenque",
    //     id: "caminos palenque",
    //     img: image,
    //     lng: 17.505,
    //     lat: -91.7839,
    //     zoom: 10,
    // },
    // {
    //     name: "Tramo 7 MR",
    //     id: "tramo7mr",
    //     img: image,
    //     lng: 18.3874,
    //     lat: -90.2336,
    //     zoom: 9.66,
    // },
];

export default regions;
