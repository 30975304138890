import React, { useState } from 'react'
import visual from "./vectors/visual.PNG";
import ndvi1 from "./vectors/ndvi1.PNG";
import ndvi2 from "./vectors/ndvi2.PNG";
import ndvi3 from "./vectors/ndvi3.PNG";
import sar from "./vectors/sar.PNG";
import ndvi from "./vectors/ndvi.png";

export const SidebarRight = ({ lat, lng, right }) => {
    const [areRegionsVisible, setAreRegionsVisible] = useState(false);
    const [selectedSpectrum, setSelectedSpectrum] = useState("Visual")

    const OptionRow = (props) => {

        return (
            <label className={`flex flex-row my-1 mx-4 rounded px-4 ${selectedSpectrum === props.title ? 'bg-gray-300' : ''}`} onClick={(e) => setSelectedSpectrum(props.title)}>
                <div className="flex my-auto pl-2 ">
                    <img alt="" src={props.img} className="w-18 rounded  h-14 object-cover my-1 py-1 " />
                </div>
                <div className="flex flex-col w-3/4 mx-auto text-xs my-auto">
                    <h1 className="my-auto text-justify">
                        <span className="font-bold">{props.title} :</span> {props.children}
                    </h1>
                </div>

            </label>
        )
    }

    return (
        <div className="z-40">
            <div className={`flex fixed right-${right} top-24 bg-black bg-opacity-50 rounded-2xl pl-4 mr-2`}>
                {/* <p className="text-white px-2 my-auto "> {selectedSpectrum} </p>
                <p className="text-white px-2 my-auto "> | </p> */}
                <p className="text-white px-2 pr-4 my-auto">Lat: {lat} °N, {lng} ° W  </p>
                {/* <button
                    className="flex flex-row  py-2 mx-auto w-48 bg-color1 border-1 text-black rounded-lg  "
                    onClick={() => setAreRegionsVisible(!areRegionsVisible)}
                >
                    <img src={ndvi} className="flex w-8 mx-auto" alt="" />
                    <h1 className="flex mx-auto my-auto"> Tipo de espectro</h1>
                </button> */}
            </div>
            {areRegionsVisible ? (
                <div className={`flex flex-col bg-white text-black rounded-xl py-6 w-1/3 fixed right-${right} top-36`}>
                    <OptionRow setSelectedSpectrum={setSelectedSpectrum} img={visual} title="Visual"> ortorrectificado, afiladdo y con corrección de color (usando una curva de color) Imágenes RGB de 3 bandas. </OptionRow>
                    <hr />
                    <OptionRow setSelectedSpectrum={setSelectedSpectrum} img={ndvi1} title="NDVI (Pansharpened Multiespectral)"> Imágenes BGRN de 4 bandas sharpened y ortorrectificadas. </OptionRow>
                    <hr />
                    <OptionRow setSelectedSpectrum={setSelectedSpectrum} img={ndvi2} title="NDVI (SR analítico)"> BGRN multiespectral ortorrectificado. Reflectancia superficial corregida atmosféricamente. </OptionRow>
                    <hr />
                    <OptionRow setSelectedSpectrum={setSelectedSpectrum} img={ndvi3} title="NDVI (Analítico)">
                        BGRN multiespectral ortorrectificado. Correciones radiométricas aplicadas para corregir cualquier artefacto y transformación en resplandor de la cima de la atmósfera. </OptionRow>
                    <hr />
                    <OptionRow setSelectedSpectrum={setSelectedSpectrum} img={sar} title="(SAR) Pancromático"> ortorrectificado, radiométricamente correcto, pancromático (PAN). </OptionRow>
                </div>
            ) : (
                ""
            )}
        </div>
    )
}
