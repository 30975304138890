import './index.css';
import AppRouter from './routers/AppRouter';
import { LiveChatLoaderProvider } from 'react-live-chat-loader'


require('dotenv').config()

const App = () => {

  return (
    <LiveChatLoaderProvider providerKey="d63feb3887c9464fb3f9e58855df03bcb88898159dfe43c09801bd372a073863" provider="userlike" >
      <AppRouter />
    </LiveChatLoaderProvider>
  );
}

export default App;
