import React, { useState, useContext, useEffect } from "react";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import TilesetsGroup from "../../../../models/tilesetsgroup";
import { flyTo } from "../helpers";
import MapContext from "../MapsContext";
import tren_maya from "../components/vectors/tren_maya.png";
import chincheta_ from "../components/vectors/chincheta.png";

import regions from "../../../../data/regionsData";

export const MainTileSetList = ({
  mainTileSet,
  changeMainTileset,
  tilesetList,
  setTileSetList,
  selectedRegion,
  setSelectedRegion,
}) => {
  const mapContext = useContext(MapContext);
  const tilesets = new TilesetsGroup();
  const [isMainTilesetRadiosEnabled, setIsMainTilesetRadiosEnabled] =
    useState(true);
  const [isMainTilesetRadioListVisible, setIsMainTilesetRadioListVisible] =
    useState(true);
  const [areRegionsVisible, setAreRegionsVisible] = useState(true);

  const changeTileset = (tileset) => {
    setIsMainTilesetRadiosEnabled(false);
    changeMainTileset(tileset);
    setTimeout(() => {
      setIsMainTilesetRadiosEnabled(true);
    }, 800);
  };

  const filterByRegion = (region) => {
    setSelectedRegion(region);
    flyTo(mapContext.allMapsRefs, region.lng, region.lat, region.zoom);
  };

  useEffect(() => {
    const filtered = tilesets.tilesets.filter(
      (tileset) => tileset.region === selectedRegion.id
    );
    setTileSetList(filtered);
  }, [selectedRegion, setTileSetList, tilesets.tilesets]);

  return (
    <div className="z-10 rounded bg-white rounded-r-2xl w-96 my-auto mx-auto py-2">
      {/* <div className="flex flex-row justify-evenly">
        <div className="flex text-black ml-8">
          <img src={tren_maya} className="h-2/3 my-auto" alt="" />
        </div>
        <div className="flex text-black my-auto w-3/4">
          <p className="font-bold">
            {selectedRegion.lng}&#176;N, &nbsp; {selectedRegion.lat}&#176; W
          </p>
        </div>
      </div> */}
      {/* <hr /> */}
      <button
        className="bg-trasparent text-black flex mx-auto my-auto w-max mt-5 mb-3"
        onClick={() => setAreRegionsVisible(!areRegionsVisible)}
      >
        <img src={chincheta_} alt="" className="w-1/12 mr-3" />
        <div className="">Seleccione punto de observación </div>
        {/* {areRegionsVisible ? <FaAngleUp /> : <FaAngleDown />} */}
      </button>
      {areRegionsVisible ? (
        //TODO Botón para centrar en el poligono
        <div className="px-2 bg-white  rounded text-black w-max mx-auto pb-3 grid grid-cols-4">
          {regions.map((region) => (
            <label className="" key={Math.random()}>
              <img
                src={region.img}
                alt=""
                className={` rounded-full w-16 h-16 p-1 border-4   ${selectedRegion.id === region.id
                  ? "border-color1"
                  : "border-white"
                  }`}
              />
              <button
                className="mt-2 -mr-1 hidden"
                name="region"
                id={region.id}
                value={region.id}
                checked={selectedRegion.id === region.id}
                onClick={() => filterByRegion(region)}
                disabled={!isMainTilesetRadiosEnabled}
              />
              <p
                className={` text-xs text-center ${selectedRegion.id === region.id ? "font-bold" : "font-medium"
                  }`}
              >
                {" "}
                &#160; {region.name}
              </p>
            </label>
          ))}
        </div>
      ) : (
        ""
      )}
      {/* <hr />
      <button
        className="< bg-color5 top-0 px-4 py-2 rounded-xl flex mx-auto w-100 my-3"
        onClick={() =>
          setIsMainTilesetRadioListVisible(!isMainTilesetRadioListVisible)
        }
      >
        <div className="mr-3 text-white "> Seleccione fecha de Registro</div>
        <div className="mt-1 mr-2">
          {isMainTilesetRadioListVisible ? <FaAngleUp /> : <FaAngleDown />}
        </div>
      </button>

      {isMainTilesetRadioListVisible ? (
        <ul className="px-2 bg-white rounded text-black w-max mx-auto ">
          {tilesetList.map((tileset) => (
            <label className="flex" key={Math.random()}>
              <input
                type="radio"
                className="mt-2 -mr-1"
                name="mainTileSet"
                id={tileset.id}
                value={tileset.id}
                checked={mainTileSet.id === tileset.id}
                onChange={() => changeTileset(tileset)}
                disabled={!isMainTilesetRadiosEnabled}
              />
              <p> &#160; {tileset.name}</p>
            </label>
          ))}
        </ul>
      ) : (
        ""
      )} */}
    </div>
  );
};
