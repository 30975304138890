const mapSyles = [
    {
        image: "./static/img/styles/streets.jpg",
        title: "Calles",
        value: "calles",
        // id: 'mapbox://styles/mapbox/streets-v11'
        id: 'mapbox://styles/mapbox/satellite-streets-v11'


    },
    {
        image: "./static/img/styles/outdoors.jpg",
        title: "Outdoors",
        value: "outdoors",
        id: 'mapbox://styles/christianjltz/ckw1043ni3fy514ujeeug7b55'

        //id:'mapbox://styles/mapbox/outdoors-v11'

    },
    {
        image: "./static/img/styles/light.jpg",
        title: "Dia",
        value: "dia",
        id: 'mapbox://styles/mapbox/light-v10'
    },
    {
        image: "./static/img/styles/dark.jpg",
        title: "Noche",
        value: "noche",
        id: 'mapbox://styles/mapbox/dark-v10'
    },
    {
        image: "./static/img/styles/satelite.jpg",
        title: "Satelite",
        value: "satelite",
        id: 'mapbox://styles/mapbox/satellite-v9'
    },
    {
        image: "./static/img/styles/satelite-calles.jpg",
        title: "Sat. con calles",
        value: "satelite-outdoors",
        id: 'mapbox://styles/mapbox/satellite-streets-v11'
    },
]

export default mapSyles;