
class Lines {
    constructor(legend, id, lat, lng, zoom,source) {
        this.legend = legend;
        this.id = id;
        this.lat = lat;
        this.lng = lng;
        this.zoom = zoom; 
        this.source = source;       
    }
}

export default Lines;