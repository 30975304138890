import React from 'react'

export const WheatherDay = ({ min, max, wT, wK, dia }) => {
    return (
        <div className='text-center'>
            <div>{dia}</div>
            <div className=''>
                <img width={50} src={`https://developer.accuweather.com/sites/default/files/${wK}-s.png`} />
            </div>
            <div className='font-black'>
                {min} / {max}
            </div>
        </div>
    )
}

