import Tileset from "../models/tilesets"

export const tilesetsData = [

    // new Tileset("Punto 1", "2021/11/06 RGB", "christianjltz.punto1_20211106_8bits", "2021/11/06 rgb", "raster", null,),
    // new Tileset("Punto 1", "2021/11/06 NDVI", "christianjltz.punto1_20211106ndvicolor", "2021/11/06 ndvi", "raster", null,),

    // new Tileset("Punto 1", "2021/10/30 RGB", "christianjltz.punto1_20211030_8bits", "2021/10/30 rgb", "raster", null,),
    // new Tileset("Punto 1", "2021/10/30 NDVI", "christianjltz.punto1_20211030ndvicolor", "2021/10/30 ndvi", "raster", null,),

    // new Tileset("Punto 1", "2021/10/30 SAR", "christianjltz.3u9psprm", "2021/10/30 sar", "raster", null,),

    // new Tileset("Punto 1", "2021/10/30 RGB II", "christianjltz.bzkcgrnh", "2021/10/30 rgb2", "raster", null,),
    // new Tileset("Punto 1", "2021/10/30 NDVI II", "christianjltz.2x57mr4o", "2021/10/30 ndvi2", "raster", null,),

    // new Tileset("Punto 1", "2021/05/01 RGB", "christianjltz.chxodi9o", "2021/05/01 rgb", "raster", null,),
    // new Tileset("Punto 1", "2021/05/01 NDVI", "christianjltz.2cvh1w6n", "2021/05/01 ndvi", "raster", null,),

    // new Tileset("Punto 1", "2021/11/03 SAR", "christianjltz.punto1_sar_20211103", "2021/11/03 sar", "raster", null,),
    // new Tileset("Punto 1", "2021/11/15 SAR", "christianjltz.punto1_sar_20211115", "2021/11/15 sar", "raster", null,),


    // new Tileset("Punto 2", "2021/11/10 RGB", "christianjltz.punto2_20211110_8bits", "2021/11/10 rgb", "raster", null,),
    // new Tileset("Punto 2", "2021/11/10 NDVI", "christianjltz.punto2_20211110ndvicolor", "2021/11/10 ndvi", "raster", null,),


    // new Tileset("Punto 3", "2021/11/06 RGB", "christianjltz.punto3_20211106_8bits", "2021/11/06 rgb", "raster", null,),
    // new Tileset("Punto 3", "2021/11/06 NDVI", "christianjltz.punto3_20211106ndvicolor", "2021/11/06 ndvi", "raster", null,),


    // new Tileset("Punto 4", "2021/11/08 RGB", "christianjltz.punto4_20211108_8bits", "2021/11/08 rgb", "raster", null,),
    // new Tileset("Punto 4", "2021/11/08 NDVI", "christianjltz.punto4_20211108ndvicolor", "2021/11/08 ndvi", "raster", null,),

    // new Tileset("Punto 4", "2020/02/29 RGB", "christianjltz.tramo_centro_29_febrero_2020", "2020/02/29 rgb", "raster", null,),
    // new Tileset("Punto 4", "2020/02/29 NDVI", "christianjltz.tramocentro29022020ndvicolor", "2020/02/29 ndvi", "raster", null,),

    // new Tileset("Chetumal", "2021/11/08 RGB", "christianjltz.1gajop08", "2021/11/08 rgb", "raster", null,),
    // new Tileset("Chetumal", "2021/11/08 NDVI", "christianjltz.chetumal20211108ndvicolor", "2021/11/08 ndvi", "raster", null,),


    // new Tileset("tramo7mr", "Tramo 7 - 2021/10/25", "christianjltz.tramo_unido_8bits", "tramo 7", "raster", null,), //cambiar bandas

    // new Tileset("caminos palenque", "2021/06/22 RGB", "christianjltz.Palenque_20210622_8bits", "2021/06/22 rgb", "raster", null,),
    // new Tileset("caminos palenque", "2021/06/22 NDVI", "christianjltz.Palenque20210622ndvicolor", "2021/06/22 ndvi", "raster", null,),

    // new Tileset("caminos palenque", "2021/08/07 RGB", "christianjltz.Palenque_20210807_8bits", "2021/08/07 rgb", "raster", null,),
    // new Tileset("caminos palenque", "2021/08/07 NDVI", "christianjltz.Palenque20210807ndvicolor", "2021/08/07 ndvi", "raster", null,),

    // new Tileset("caminos palenque", "2020/08/01 - 2020/09/01 Mapa", "christianjltz.mapa_agosto_septiembre_2020", "2020/08/01 - 2020/09/01 mapa", "raster", [
    //     new Tileset("caminos palenque", "2020/08/01 - 2020/09/01 Camino", "christianjltz.camino_agosto_septiembre_2020", "2020/08/01 - 2020/09/01 camino", "raster", null),
    // ]),
    // new Tileset("caminos palenque", "2020/08/01 - 2020/09/01 Mapa", "christianjltz.mapa_agosto_septiembre_2020", "2020/08/01 - 2020/09/01 mapa", "raster", [
    //     new Tileset("caminos palenque", "2020/08/01 - 2020/09/01 Camino", "christianjltz.camino_agosto_septiembre_2020", "2020/08/01 - 2020/09/01 camino", "raster", null),
    // ]),
    // new Tileset("caminos palenque", "2020/12/01 - 2021/01/01 Mapa", "christianjltz.mapa_diciembre_enero_2020_2021", "2020/12/01 - 2021/01/01 mapa", "raster", [
    //     new Tileset("caminos palenque", "2020/12/01 - 2021/01/01 Camino", "christianjltz.camino_diciembre_enero_2020_2021", "2020/12/01 - 2021/01/01 camino", "raster", null,),
    // ]),
    // new Tileset("caminos palenque", "2021/04/01 - 2021/05/01 Mapa", "christianjltz.mapa_abril_mayo_2021", "2021/04/01 - 2021/05/01 mapa", "raster", [
    //     new Tileset("caminos palenque", "2021/04/01 - 2021/05/01 Camino", "christianjltz.camino_abril_mayo_2021", "2021/04/01 - 2021/05/01 camino", "raster", null,),
    // ]),
    new Tileset("caminos palenque", "2021/08/01 - 2021/09/01 Mapa", "christianjltz.mapa_agosto_septiembre_2021", "2021/08/01 - 2021/09/01 mapa", "raster", [
        new Tileset("caminos palenque", "2021/08/01 - 2021/09/01 Camino", "christianjltz.camino_agosto_septiembre_2021", "2021/08/01 - 2021/09/01 camino", "raster", null,),
    ]),



    // new Tileset("Tramo extendido", "xphonse.4s9x40f8", "2021-10-30","raster",
    // [new Tileset( "Camino", "xphonse.4v4d0314", "2021-10-30", "raster",[])]
    // ),

]   