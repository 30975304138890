import React, { useEffect, useState } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import UserContext from "../context/UserContext";
import LoginScreen from "../screens/auth/LoginScreen";
import MapContainer from "../screens/maps/timeline/MapContainer";
import { PrivateRouter } from "./PrivateRouter";



const AppRouter = () => {
    const [isLogged, setIsLogged] = useState(false);
    const userData = { isLogged, setIsLogged }

    useEffect(() => {
        var logged = localStorage.getItem('aposdijfmasoidjhfaowerfio');
        if (logged === "ao;wehnrpaoimgaihneasodfa") {
            setIsLogged(true)
        };
    }, [])

    return (
        <UserContext.Provider value={userData}>
            <Router>
                <>
                    <Switch>
                        {/* <Route exact path="/" > */}
                        {/* <LoginScreen setIsLogged={setIsLogged} isLogged={true} /> */}
                        {/* <LoginScreen setIsLogged={setIsLogged} isLogged={isLogged} /> */}
                        {/* </Route> */}
                        <PrivateRouter path="/" component={MapContainer} isAuthenticated={true} />
                        {/* <PrivateRouter path="/map" component={MapContainer} isAuthenticated={isLogged} /> */}
                        <Route path="/">
                            <div className="flex justify-center align-middle h-full w-full bg-gray-700"><h5 className="text-gray-200 my-auto font-thin text-4xl">Not found</h5></div>
                        </Route>
                    </Switch>
                </>
            </Router>
        </UserContext.Provider>
    );
}

export default AppRouter;