import React, { useRef, useEffect, useContext } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxCompare from "!mapbox-gl-compare"; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
import 'mapbox-gl-compare/dist/mapbox-gl-compare.css';
import MapContext from '../MapsContext';
// import { addLayer, addSource,addSearchBox,loadChildrenIfExist,addAllVectors } from '../helpers';
import { addLayer, addSource, loadChildrenIfExist, addAllVectors } from '../helpers';

mapboxgl.accessToken = 'pk.eyJ1IjoieHBob25zZSIsImEiOiJjazRxa2MyeTA0MDBvM25td3F1MzAwdnFoIn0.6zfE0pqueFa-wPeAAMeQOw';

const CompareMap = ({ isComparingActive, tilesetForComparation, setCompareBeforeMap, setCompareAfterMap, mainTileSet }) => {
    const mapContext = useContext(MapContext);
    const mapContainer = useRef(null);
    const map = useRef(null);
    const beforeRef = useRef();
    const afterRef = useRef();
    const beforeMap = useRef();
    const afterMap = useRef();

    useEffect(() => {
        beforeMap.current = new mapboxgl.Map({
            container: 'mapBefore',
            style: mapContext.mainBackgroundStyle,
            center: [mapContext.lng, mapContext.lat],
            zoom: mapContext.zoom,
            attributionControl: false,
            logoPosition: 'bottom-right'
        });
        beforeMap.current.on('load', () => {
            addSource(beforeMap, mainTileSet);
            addLayer(beforeMap, mainTileSet);
            loadChildrenIfExist(beforeMap, mainTileSet);
            addAllVectors(beforeMap);
            // addSearchBox(beforeMap);
        });

        afterMap.current = new mapboxgl.Map({
            container: 'mapAfter',
            style: mapContext.mainBackgroundStyle,
            center: [mapContext.lng, mapContext.lat],
            zoom: mapContext.zoom,
            attributionControl: false,
            logoPosition: 'bottom-left'
        });
        afterMap.current.on('load', () => {
            addSource(afterMap, tilesetForComparation);
            addLayer(afterMap, tilesetForComparation);
            addAllVectors(afterMap);
            loadChildrenIfExist(afterMap, tilesetForComparation);
        });

        map.current = new MapboxCompare(beforeMap.current, afterMap.current, "#comparison-container", {}, []);
        setCompareBeforeMap(beforeMap);
        setCompareAfterMap(afterMap);
        return (() => {
            setCompareBeforeMap(null);
            setCompareAfterMap(null);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // no remover [], 

    useEffect(() => {
        if (!afterMap.current) return; // wait for map to initialize
        afterMap.current.on('move', (e) => {
            mapContext.setLng(afterMap.current.getCenter().lng.toFixed(4));
            mapContext.setLat(afterMap.current.getCenter().lat.toFixed(4));
            mapContext.setZoom(afterMap.current.getZoom().toFixed(2));
        });
    });

    return (
        <div ref={mapContainer} className={` mapboxgl-map ${isComparingActive ? '' : 'hidden'}`} id="comparison-container" style={{ width: '100%' }} >
            <div id="mapBefore" className="map" ref={beforeRef} style={{ width: '100%', height: '100%', position: "absolute", top: 0, bottom: 0, left: 0 }}></div>
            <div id="mapAfter" className="map" ref={afterRef} style={{ width: '100%', height: '100%', position: "absolute", top: 0, bottom: 0, left: 0 }}></div>
        </div>
    );
}

export default CompareMap;