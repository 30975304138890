class Tileset{
    constructor(region,name,id,date,type,children){
        this.region=region;
        this.name=name;
        this.id=id;
        this.date=date;
        this.type=type;        
        this.children=children;        
    }
}
export default Tileset;