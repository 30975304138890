import { tilesetsData } from "../data/tilesetsData";



class TilesetsGroup {
    constructor(){
        this.tilesets = tilesetsData;
    }
    //TODO Por ahora retorna solo el ultimo del array, hacer que ordene por fecha y retorne el del ultimo dia 
    returnLatestTileset = () => {
        return this.tilesets[0];
    }
}

export default TilesetsGroup;